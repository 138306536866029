export default [
  {
    path: '/apps/test',
    name: 'apps-test',
    component: () => import('@/views/test/main.vue'),
    meta: {
      pageTitle: 'ZONE DE TESTS',
      breadcrumb: [
        {
          text: 'Espace Tests',
          active: true,
        }
      ],
    },
  },
  {
    path: '/apps/sample',
    name: 'apps-sample',
    component: () => import('@/views/sample/main.vue'),
    meta: {
      pageTitle: 'View Sample',
      breadcrumb: [
        {
          text: 'Espace Sample',
          active: true,
        }
      ],
    },
  },
  // ESPACE MEDICAL -------------------
  {
    path: '/apps/medical/centre/save',
    name: 'apps-medical-centre-save',
    component: () => import('@/views/apps/medical/centre/save.vue'),
    meta: {
      pageTitle: 'Enregistrer',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Enregistrer',
          active: false,
          to: {name: 'apps-medical-centre-save'}
        },
      ],
    },
  },
  {
    path: '/apps/medical/centre/save',
    name: 'apps-medical-centre-save',
    component: () => import('@/views/apps/medical/centre/save.vue'),
    meta: {
      pageTitle: 'Enregistrer',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Enregistrer',
          active: false,
          to: {name: 'apps-medical-centre-save'}
        },
      ],
    },
  },
  {
    path: '/apps/medical/centre/edit/:slug',
    name: 'apps-medical-centre-edit',
    component: () => import('@/views/apps/medical/centre/save.vue'),
    meta: {
      pageTitle: 'Modification d\'un centre',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Modification',
          active: false,
          to: {name: 'apps-medical-centre-edit'}
        },
      ],
    },
  },
  // PHARMACIE
  {
    path: '/apps/medical/pharmacie/agree',
    name: 'apps-medical-pharmacie-agree',
    component: () => import('@/views/apps/medical/centre/pharmacie.vue'),
    meta: {
      pageTitle: 'Pharmacies Agrées',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Pharmacies Agrées',
          active: false,
          to: {name: 'apps-medical-pharmacie-agree'}
        },
      ],
    },
  },
  // PHARMACIE DE GARDE
  {
    path: '/apps/medical/pharmacie/garde',
    name: 'apps-medical-pharmacie-garde',
    component: () => import('@/views/apps/medical/centre/pharmacie-garde.vue'),
    meta: {
      pageTitle: 'Pharmacies de garde',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Pharmacies de garde',
          active: false,
          to: {name: 'apps-medical-pharmacie-garde'}
        },
      ],
    },
  },
  // CENTRE
  {
    path: '/apps/medical/centre/save',
    name: 'apps-medical-centre-save',
    component: () => import('@/views/apps/medical/centre/save.vue'),
    meta: {
      pageTitle: 'Gestion des médecins',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Gestion de médecins',
          active: false,
          to: {name: 'apps-medical-centre-save'}
        },
      ],
    },
  },
  // CLINIC ET HOPITUAX
  {
    path: '/apps/medical/centre',
    name: 'apps-medical-centre',
    component: () => import('@/views/apps/medical/centre/main.vue'),
    meta: {
      pageTitle: 'Cliniques et hôpitaux membres',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Cliniques et hôpitaux membres',
          active: false,
          to: {name: 'apps-medical-centre'}
        },
      ],
    },
  },
  // CLINIC ET HOPITUAX AGREE CNPS
  {
    path: '/apps/medical/centre/agree',
    name: 'apps-medical-centre-agree',
    component: () => import('@/views/apps/medical/centre/cnps.vue'),
    meta: {
      pageTitle: 'Cliniques agrées CNPS',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Cliniques agrées CNPS',
          active: false,
          to: {name: 'apps-medical-centre-agree'}
        },
      ],
    },
  },
  // INFIRMERIES CIE
  {
    path: '/apps/medical/centre/infirmerie',
    name: 'apps-medical-centre-infirmerie',
    component: () => import('@/views/apps/medical/centre/infirmerie.vue'),
    meta: {
      pageTitle: 'Infirmeries CIE',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Infirmeries CIE',
          active: false,
          to: {name: 'apps-medical-centre-infirmerie'}
        },
      ],
    },
  },
  // RENDEZVOUS - ESPACE MEDICAL
  {
    path: '/apps/medical/rendezvous',
    name: 'apps-medical-rendezvous',
    component: () => import('@/views/apps/medical/rendezvous/rendezvous.vue'),
    meta: {
      pageTitle: 'Rendez-vous',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Rendez-vous',
          active: false,
          to: {name: 'apps-medical-rendezvous'}
        },
      ],
    },
  },
  // doctor
  {
    path: '/apps/medical/doctor',
    name: 'apps-medical-doctor',
    component: () => import('@/views/apps/medical/doctor/main.vue'),
    meta: {
      pageTitle: 'Gestion des médecins',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Gestion de médecins',
          active: false,
          to: {name: 'apps-medical-doctor'}
        },
      ],
    },
  },
  // produit
  {
    path: '/apps/medical/produit',
    name: 'apps-medical-produit',
    component: () => import('@/views/apps/medical/produit/main.vue'),
    meta: {
      pageTitle: 'Gestion des Frais et Soins',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Gestion des Frais et Soins',
          active: false,
          to: {name: 'apps-medical-produit'}
        },
      ],
    },
  },
  // VSA & VMS
  {
    path: '/apps/medical/vsa',
    name: 'apps-medical-vsa',
    component: () => import('@/views/apps/medical/vsavms/vsa.vue'),
    meta: {
      pageTitle: 'Gestion du Programme VSA',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Gestion du Programme VSA',
          active: false,
          to: {name: 'apps-medical-vsa'}
        },
      ],
    },
  },
  {
    path: '/apps/medical/vms',
    name: 'apps-medical-vms',
    component: () => import('@/views/apps/medical/vsavms/vms.vue'),
    meta: {
      pageTitle: 'Gestion du Programme VMS',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Gestion du Programme VMS',
          active: false,
          to: {name: 'apps-medical-vms'}
        },
      ],
    },
  },
  {
    path: '/apps/medical/vsavms/direction',
    name: 'apps-medical-vsavms-direction',
    component: () => import('@/views/apps/medical/vsavms/direction.vue'),
    meta: {
      pageTitle: 'Gestion des exploitations',
      breadcrumb: [
        {
          text: 'Espace Médical',
          active: true,
        },
        {
          text: 'Gestion des exploitations',
          active: false,
          to: {name: 'apps-medical-vsavms-direction'}
        },
      ],
    },
  },
  // COMMUNICATION
  {
    path: '/apps/post/communication',
    name: 'apps-post-communication',
    component: () => import('@/views/apps/post/communication/main.vue'),
    meta: {
      pageTitle: 'Espace Communication',
      breadcrumb: [
        {
          text: 'Espace Communication',
          active: true,
        }
      ],
    },
  }, 
  // Conformite
  {
    path: '/apps/post/conformite',
    name: 'apps-post-conformite',
    component: () => import('@/views/apps/post/conformite/main.vue'),
    meta: {
      pageTitle: 'Espace Charte',
      breadcrumb: [
        {
          text: 'Espace Charte',
          active: true,
        }
      ],
    },
  },
  // Politique
  {
    path: '/apps/post/politique',
    name: 'apps-post-politique',
    component: () => import('@/views/apps/post/politique/main.vue'),
    meta: {
      pageTitle: 'Espace Politique',
      breadcrumb: [
        {
          text: 'Espace Politique',
          active: true,
        }
      ],
    },
  },
  // MUTUELLE
  {
    path: '/apps/post/mutuelle-fcp',
    name: 'apps-post-mutuelle',
    component: () => import('@/views/apps/post/mutuelle/main.vue'),
    meta: {
      pageTitle: 'Espace Mutuelle FCP',
      breadcrumb: [
        {
          text: 'Espace Mutuelle FCP',
          active: true,
        }
      ],
    },
  },
  // DEMARCHE
  {
    path: '/apps/post/demarche',
    name: 'apps-post-demarche',
    component: () => import('@/views/apps/post/demarche/main.vue'),
    meta: {
      pageTitle: 'Espace Demarche RSE/QSE/DEV Durable',
      breadcrumb: [
        {
          text: 'Espace Demarche',
          active: true,
        }
      ],
    },
  },
  // CADRE INSTITUTIONNEL
  {
    path: '/apps/post/cadre-institutionnel',
    name: 'apps-post-cadre-institutionnel',
    component: () => import('@/views/apps/post/cadre-institutionnel/main.vue'),
    meta: {
      pageTitle: 'Espace Cadre Institutionnel',
      breadcrumb: [
        {
          text: 'Espace Cadre Institutionnel',
          active: true,
        }
      ],
    },
  },
  // RH
  {
    path: '/apps/post/rh',
    name: 'apps-post-rh',
    component: () => import('@/views/apps/post/rh/main.vue'),
    meta: {
      pageTitle: 'Espace RH',
      breadcrumb: [
        {
          text: 'Espace RH',
          active: true,
        }
      ],
    },
  },
  // PARTENAIRES SOCIAUX
  {
    path: '/apps/post/partenaire-sociaux',
    name: 'apps-post-partenaire-sociaux',
    component: () => import('@/views/apps/post/partenaire-sociaux/main.vue'),
    meta: {
      pageTitle: 'Espace Partenaire Sociaux',
      breadcrumb: [
        {
          text: 'Espace Partenaire Sociaux',
          active: true,
        }
      ],
    },
  },
  // SECURITE - NUMERO
  {
    path: '/apps/securite/numero',
    name: 'apps-securite-numero',
    component: () => import('@/views/apps/securite/numero/main.vue'),
    meta: {
      pageTitle: 'Numéros Utiles et Consignes de Sécurité',
      breadcrumb: [
        {
          text: 'Espace Sécurité',
          active: true,
        }
      ],
    },
  },
  // SECURITE - ALERT
  {
    path: '/apps/securite/alert',
    name: 'apps-securite-alert',
    component: () => import('@/views/apps/securite/alert/main.vue'),
    meta: {
      pageTitle: 'Alertes de Sécurité',
      breadcrumb: [
        {
          text: 'Espace Sécurité',
          active: true,
        }
      ],
    },
  },
  // SECURITE - RENDEZVOUS
  {
    path: '/apps/securite/rendezvous',
    name: 'apps-securite-rendezvous',
    component: () => import('@/views/apps/securite/rendezvous/main.vue'),
    meta: {
      pageTitle: 'Rendez-Vous',
      breadcrumb: [
        {
          text: 'Espace Sécurité',
          active: true,
        }
      ],
    },
  },
  // SECURITE - ASTREINTE
  {
    path: '/apps/securite/astreinte',
    name: 'apps-securite-astreinte',
    component: () => import('@/views/apps/securite/astreinte/main.vue'),
    meta: {
      pageTitle: 'Astreinte',
      breadcrumb: [
        {
          text: 'Espace Sécurité',
          active: true,
        }
      ],
    },
  },
  // SECURITE - Astreinte User
  {
    path: '/apps/securite/astreinte/user',
    name: 'apps-securite-astreinte-user',
    component: () => import('@/views/apps/securite/astreinte/user.vue'),
    meta: {
      pageTitle: 'Collaborateurs - Astreinte',
      breadcrumb: [
        {
          text: 'Espace Sécurité',
          active: true,
        }
      ],
    },
  },
  {
    path: '/apps/securite/astreinte/edit/:slug',
    name: 'apps-securite-astreinte-edit',
    component: () => import('@/views/apps/securite/astreinte/edit.vue'),
    meta: {
      pageTitle: 'Modification d\'article',
      breadcrumb: [
        {
          text: 'Astreinte - Espace Sécurité',
          to: {name: 'apps-securite-astreinte'},
        },
        {
          text: 'Modification',
          active: true,
        }
      ],
    },
  },
  // UTILISATEUR
  {
    path: '/apps/user',
    name: 'apps-user',
    component: () => import('@/views/apps/user/main.vue'),
    meta: {
      pageTitle: 'Collaborateurs',
      breadcrumb: [
        {
          text: 'Collaborateurs',
          active: true,
        }
      ],
    },
  },
]
