export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    meta: {
      pageTitle: 'Tableau de bord',
    },
    component: () => import('@/views/dashboard.vue'),
  }
]
