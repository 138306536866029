import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: (process.env.VUE_APP_ENV === 'prod' ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_BASEURL_DEV),
  //timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
